







import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import welcomePic from '@/assets/imgs/common/welcome.png'

@Component
export default class Home extends Vue {
  @State((state) => state.system.token) token!: string;
  @State((state) => state.system.systemTitle) systemTitle!: string;

  welcomePic = welcomePic;
}
